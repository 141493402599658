<template>
  <b-card v-if="businessData !== null">
    <b-row>
      <b-col
        cols="12"
        class="mb-3"
      >
        <b-img
          v-if="businessData.cover"
          :src="`${$entitiesImagesURL}/${businessData.cover}`"
          width="1000"

          thumbnail
        />
        <b-img
          v-else
          :src="require('@/assets/images/default-entities/Default_com_cover.png')"
          width="1000"

          thumbnail
        />
      </b-col>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            v-if="businessData.logo"
            :src="`${$entitiesImagesURL}/${businessData.logo}`"
            :text="avatarText(businessData.name)"
            size="104px"
            rounded
          />
          <b-avatar
            v-else
            :src="require('@/assets/images/default-entities/Default_com_logo.png')"
            :text="avatarText(businessData.name)"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ businessData.name }}
              </h4>
              <span class="card-text">{{ businessData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'edit-business', params: { id: $router.currentRoute.params.id } }"
                variant="primary"
              >
                Edit
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <b-row class="mt-3">
          <b-col cols="12">
            <h2>About</h2>
          </b-col>
          <b-col>
            <p>
              {{ businessData.about }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Street</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ businessData.address[0].street }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">City</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ businessData.address[0].city }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">State</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ businessData.address[0].state }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country</span>
            </th>
            <td class="pb-50">
              {{ businessData.address[0].country }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contact</span>
            </th>
            <td>
              {{ businessData.phone_number }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <back />
  </b-card>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import getBusiness from '@/common/compositions/Business/getBusiness'
import Back from '@/common/components/common/Back.vue'

export default {
  name: 'BusinessView',
  components: { Back },
  data() {
    return {
      businessData: null,
    }
  },
  setup() {
    const { getBusinessData } = getBusiness()
    return {
      avatarText,
      getBusinessData,
    }
  },
  created() {
    this.getBusinessData().then(res => {
      this.businessData = res
    })
  },
}
</script>

<style>

</style>
