import axiosInstances from '@/libs/axios-instances'
import router from '@/router'

export default function getBusiness() {
  const getBusinessData = () => axiosInstances.entities.get(`internalops/commercial_entity/${router.currentRoute.params.id}`)
    .then(res => {
      const businessData = res.data.data
      businessData.address = res.data.data.address.length !== 0 ? res.data.data.address : [{}] // this because address aren't found in some inpos
      return businessData
    })

  return {
    getBusinessData,
  }
}
